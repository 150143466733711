/**
 * Created by tyb on 2017/7/28.
 */
import $ from 'jquery'

const defaultOnError =  function(e) {
  console.log(e)
  alert('网络请求出错 请刷新页面')
}

function getData(url, onSuccess, onError) {
  $.ajax({
    type: "get",
    url,
    dataType: "json",
    success: onSuccess,
    error: onError && typeof onError === "function" ? onError : defaultOnError
  })
}

function postData(url, data, onSuccess, onError) {
  $.ajax({
    type: "post",
    url,
    data,
    dataType: "json",
    success: onSuccess,
    error: onError && typeof onError === "function" ? onError : defaultOnError
  })
}

export default { getData, postData }
